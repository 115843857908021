import React from 'react';
import styles from './css/FormGroup.module.css';

type FormGroupProps = {
  title: string,
  name: string,
  placeholder: string,
  value: any,
  handleChangeSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
  options: {
    value: string,
    key?: string
  }[],
  disabled?: boolean,
  required: boolean,
};

const FormGroup: React.FC<FormGroupProps> = ({ title, name, placeholder, value, handleChangeSelect, options, disabled, required }) => {
  return (
    <div className={styles.form_group}>
      <label htmlFor={name} className={`${styles.label} ${disabled ? styles.label_disabled : ''}`}>{title}</label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={handleChangeSelect}
        className={styles.input}
        style={{
          height: '100px'
        }}
        disabled={disabled}
        required={required}
        multiple
      >
        <option value="" disabled>{placeholder}</option>
        {options?.map((option) => (
          <option key={option.value} value={option.key}>{option.value}</option>
        ))}
      </select>
    </div>
  );
}

export default FormGroup;
