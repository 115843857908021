// based on: https://dev.to/franciscomendes10866/how-to-create-a-modal-in-react-3coc

import React from "react";
import styles from "./css/Modal.module.css";
import { RiCloseLine } from "react-icons/ri";

type ModalProps = {
  setIsOpen: (isOpen: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({ setIsOpen }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>Termos de uso e imagem</h5>
            <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
          </div>
          <div className={styles.modalContent}>
            <p>Olá, seguem algumas informações importantes sobre o curso, que você precisa ler e aceitar antes de continuar.</p>
            <p>Para participar, é necessário declarar que leu e entendeu os termos do curso e concorda em participar e em compartilhar os dados, da forma e para as finalidades informadas.</p>
            <ul>
              <li>O “Curso” é fornecido de maneira gratuita e para maiores de 16 anos, que possuam aplicativo WhatsApp e receberam o link de convite e estão habilitados para receber o “Curso”. Pode haver restrições, verifique.</li>
              <li>O “Curso” é realizado pela Lys Academy em parceria com o Instituto Coca-Cola Brasil, entidade que o promove. As duas instituições coletam e tratam dados pessoais dos alunos e tomam decisões sobre esse tratamento. Em alguns casos, a Lys Academy irá seguir as orientações da entidade que promove o “Curso” em relação ao tratamento de dados.</li>
              <li>O “Curso” acontecerá pelo envio de links com os conteúdos por WhatsApp. Por meio dele, os alunos terão acesso aos vídeos e atividades.</li>
              <li>Para acessar o “Curso”, o participante deverá adicionar na sua agenda do celular o número do “Curso”. Com isso, ele consente com o envio de mensagens de áudio, texto e vídeos pelo “Curso”.</li>
              <li>Ao adicionar o número do “Curso”, o aluno compartilha com a Lys e com a entidade que promove o “Curso” seu próprio número de celular e seu nome. O armazenamento do número do “Curso” no celular pelo aluno, a concordância com o recebimento de mensagens e esse compartilhamento de dados são essenciais para a participação no “Curso”, já que os materiais serão disponibilizados pelo WhatsApp. Caso o aluno não queira receber essas mensagens ou compartilhar seus dados, não poderá acessar o “Curso”.</li>
              <li>Durante a realização do “Curso”, serão oferecidas atividades, nas quais poderá haver a coleta de outros dados pessoais dos alunos, conforme determinado pela entidade que promove o “Curso”. A entidade que promove o “Curso” terá acesso aos dados dos alunos, inclusive às respostas das atividades, para verificação de aproveitamento e estatísticas.</li>
              <li>Os dados pessoais dos alunos serão protegidos por medidas de segurança, técnicas e administrativas e o tratamento desses dados segue as normas da Lei Geral de Proteção de Dados Pessoais - LGPD.</li>
              <li>Os dados pessoais dos alunos só serão usados pela Lys para finalidades diretamente relacionadas ao “Curso”: recebimento e envio de conteúdo e atividades, contato com tutores, emissão de certificado, avaliações e estatísticas de aproveitamento e envio de relatórios às entidades que promovem os “Cursos”. Poderá haver o uso de depoimentos dos alunos para demonstração a potenciais clientes.</li>
              <li>Os dados pessoais dos alunos serão usados pela entidade que promove o “Curso” para finalidades diretamente relacionadas ao “Curso”, divulgação de outras iniciativas de capacitação para o mercado de trabalho ou conexão com oportunidades de geração de renda: recebimento e envio de conteúdo, mensagens e atividades, contato com educadores, avaliações e estatísticas de aproveitamento para geração de relatórios. Poderá haver o uso de depoimentos dos alunos para demonstração de potenciais oportunidades de geração de renda.</li>
              <li>Os dados pessoais dos participantes poderão ser compartilhados com parceiros e clientes com a finalidade de promover e ampliar o alcance do “Curso”, pesquisas e avaliações, outras capacitações ou conexão com oportunidades de geração de renda. </li>
              <li>Os dados pessoais dos alunos serão mantidos pela entidade que promove o “Curso” até que seja verificado que a finalidade foi alcançada ou de que os dados deixaram de ser necessários ou pertinentes ao alcance da finalidade prevista.</li>
              <li>Os alunos do “Curso”, como titulares de dados, têm os direitos previstos pelo artigo 18 da LGPD, inclusive de revogação do consentimento. Pedidos e comunicações a respeito desses direitos e de proteção de dados deverão ser enviados para o e-mail <a href="mailto:lys@lys.academy">lys@lys.academy</a>.</li>
              <li>O “Curso” tem o direito de excluir o participante que tenha comportamento inadequado.</li>
              <li>O participante pode sair do curso, a qualquer momento, devendo informar sua saída através do WhatsApp. Seus dados serão excluídos em até 30 dias após seu desligamento do “Curso”.</li>
              <li>Participantes com participação inferior a 70% das atividades não receberão certificados.</li>
              <li>O participante será responsável por todas as informações e mensagens enviadas, incluindo textos, áudios e vídeos.</li>
              <li>O participante não tem o direito de divulgar, compartilhar ou publicar o conteúdo do curso em nenhum tipo de mídia, sob pena de violação de direitos autorais e de imagem.</li>
              <li>Participar do curso não garante a obtenção de emprego ou renda.</li>
              <li>O “Curso” e todo seu conteúdo são de propriedade exclusiva da Lys Academy Ltda., CNPJ/MF nº 31.454.568/0001-06 e do Instituto Coca-cola Brasil , CNPJ nº 03.093.215/0001-92. Todos os direitos reservados. Para qualquer comentário, sugestão ou reclamação envie um e-mail para <a href="mailto:lys@lys.academy">lys@lys.academy</a> ou WhatsApp para <a target="_blank" rel="noreferrer" href="https://wa.me/5511989169058">11989169058</a>.</li>
            </ul>
            <p>TERMO DE AUTORIZAÇÃO PARA USO DE IMAGEM</p>
            <p>O aluno autoriza expressamente o uso de sua imagem pela LysAcademy e pela entidade que promove o “Curso”, consistente em foto do WhatsApp, video(s) enviado(s) em atividade do “Curso”, voz, depoimento, nome, apelido e demais atributos da personalidade, sem limite de utilizações ou território, para demonstração da LysAcademy a potenciais clientes e/ou parceiros. A presente autorização é de caráter gratuito e será válida por 10 anos. A utilização da imagem não prejudicará em nenhuma hipótese a honra e identidade pessoal do aluno, tampouco será feita em contrariedade à lei.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
