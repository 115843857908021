import styles from './css/Cards.module.css';
import Briefcase from '../../assets/briefcase.svg';
import CastForEducation from '../../assets/cast_for_education.svg';
import Certificate from '../../assets/certificate.svg';
import Groups3 from '../../assets/groups_3.svg';
import MoneyOff from '../../assets/money_off.svg';
import Desktop from '../../assets/desktop_windows.svg';

const Cards: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.card_image}>
            <img src={Briefcase} alt="Mala de empresa" />
          </div>
          <div className={styles.card_content}>
            <h5 className={styles.card_title}>Conexão com vagas de empregos</h5>
            <p className={styles.card_text}>
              Com uma rede de mais de <span>400 parceiros empregadores</span>.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card_image}>
            <img src={Groups3} alt="Grupos de 3 pessoas" />
          </div>
          <div className={styles.card_content}>
            <h5 className={styles.card_title}>
              Preparação para o mundo do trabalho
            </h5>
            <p className={styles.card_text}>
              Ajudamos você com direcionamento de carreira e dicas para se
              destacar em entrevistas e processos seletivos.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card_image}>
            <img src={Desktop} alt="Ícone de computador" />
          </div>
          <div className={styles.card_content}>
            <h5 className={styles.card_title}>Videoaulas curtas</h5>
            <p className={styles.card_text}>Aprendizado rápido e prático.</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card_image}>
            <img src={Certificate} alt="Certificado conclusão" />
          </div>
          <div className={styles.card_content}>
            <h5 className={styles.card_title}>Certificado conclusão</h5>
            <p className={styles.card_text}>
              Reconhecimento oficial do seu aprendizado.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card_image}>
            <img src={MoneyOff} alt="Ícone de dinheiro" />
          </div>
          <div className={styles.card_content}>
            <h5 className={styles.card_title}>100% gratuito</h5>
            <p className={styles.card_text}>Aprenda sem gastar um centavo. </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card_image}>
            <img src={CastForEducation} alt="Tela com chapéu de estudante" />
          </div>
          <div className={styles.card_content}>
            <h5 className={styles.card_title}>100% online</h5>
            <p className={styles.card_text}>Flexibilidade total de horários.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
