import React from 'react';
import styles from './css/CocaInfos.module.css';
import cocaLogo from '../../assets/coca-logo.png';
import taqeLogo from '../../assets/taqe-logo.png';
import videoIcon from '../../assets/video-icon.svg';
import whatsappVideos from '../../assets/whatsapp-videos.png';
import cocaTeam from '../../assets/coca-team.png';
import Cards from './Cards';

const CocaInfos: React.FC = () => {
  return (
    <div>
      <div className={styles.topBackground}></div>
      <div className={styles.callToActionSection}>
        <div className={styles.callToActionContainer}>
          <div className={styles.callToActionContent}>
            <div className={styles.logosContainer}>
              <div className={styles.cocaLogo}>
                <img src={cocaLogo} alt="Logo do Instituto Coca-Cola Brasil" />
              </div>
              <div className={styles.taqeLogo}>
                <img src={taqeLogo} alt="Logo da Taqe" />
              </div>
            </div>
            <div className={styles.callToActionInfos}>
              <h2>
                Cursos Gratuitos
                <br />
                no Instituto <br />
                Coca-Cola Brasil
              </h2>
              <a href="#form">Inscreva-se!</a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.aboutSection}>
        <h1>Coletivo Online</h1>
        <div className={styles.aboutGrayInfos}>
          <div className={styles.title_container}>
            <h3 className={styles.title}>
              Por que devo fazer o <span>Coletivo Online</span>?
            </h3>
            <span className={styles.subtitle}>
              O emprego que você sempre sonhou começa aqui!
            </span>
          </div>
          <Cards />
          <div className={styles.videoInfosContainer}>
            <div className={styles.videoInfosContent}>
              <img
                src={whatsappVideos}
                alt="Visualização do curso pelo whatsapp"
              />
              <div className={styles.iconInfosContainerVideo}>
                <div className={styles.iconInfoBoxVideo}>
                  <img src={videoIcon} alt="Ícone de vídeo" />
                  <p>
                    Oferecido pelo Instituto Coca-Cola Brasil o curso é formado
                    por <span>10 videoaulas enviadas pelo WhatsApp</span> que
                    vão colaborar para sua preparação para o mundo do trabalho e
                    na busca pelo 1º emprego.
                  </p>
                </div>
                <img
                  src={cocaTeam}
                  className={styles.cocaTeam}
                  alt="Imagem do time da Coca"
                />
              </div>
            </div>
          </div>
          <div className={styles.subscribeButton}>
            <p className={styles.subscribeText}>
              Se você tem entre 16 e 29 anos, está cursando ou concluiu o ensino
              médio, esse curso é para você!
            </p>
            <a href="#form">Inscreva-se!</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CocaInfos;
