import React from 'react';
import styles from './css/FormGroup.module.css';
import { IMaskInput } from 'react-imask';
import { validate } from 'gerador-validador-cpf';
import { useForm } from '../../Utils/FormContext';

type FormGroupProps = {
  title: string,
  type: string,
  name: string,
  placeholder: string,
  value: string | any,
  errorMessage?: string,
  mask?: string,
  handleChangeInput?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleChangeSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
  options?: {
    value: string
  }[],
  disabled?: boolean,
  required: boolean,
};

const FormGroup: React.FC<FormGroupProps> = ({ title, name, type, placeholder, value, handleChangeInput, handleChangeSelect, errorMessage, mask, options, disabled = false, required }) => {
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const { setBlockSend } = useForm();

  const getOnlyNumbers = (value: string) => {
    return value.replace(/\D/g, '');
  }

  const isInvalid = () => {
    if (name.includes("cpf") && !validate(value)) {
      return true;
    }

    if ((name.includes("cpf") || name === 'telefone') && value && getOnlyNumbers(value).length !== 11) {
      return true;
    }

    return false;
  }

  React.useEffect(() => {
    if (isInvalid()) {
      setBlockSend(true);
      if (name.includes("cpf") && value) {
        setShowErrorMessage(true);
      }
    } else {
      setBlockSend(false);
      setShowErrorMessage(false);
    }
    // eslint-disable-next-line
  }, [value, setBlockSend]);

  return (
    <div className={styles.form_group}>
      <label htmlFor={name} className={`${styles.label} ${disabled ? styles.label_disabled : ''}`}>{title}</label>
      {options ?
        <select
          id={name}
          name={name}
          value={value}
          onChange={handleChangeSelect}
          className={styles.input}
          disabled={disabled}
          required={required}
        >
          <option value="" disabled>{placeholder}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>{option.value}</option>
          ))}
        </select>
        : <IMaskInput
          id={name}
          type={type}
          placeholder={placeholder}
          value={value}
          mask={mask}
          onChange={handleChangeInput}
          name={name}
          className={styles.input}
          disabled={disabled}
          required={required}
          onBlur={() => setShowErrorMessage(isInvalid())}
        />}
      {showErrorMessage && <span className={styles.error_message}>{errorMessage}</span>}
    </div>
  );
}

export default FormGroup;
