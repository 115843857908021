import { useEffect, useState } from "react";
import useFetch from "./useFetch";

export const useGetState = () => {
  const { request, data, loading } = useFetch();
  const [filteredData, setFilteredData] = useState<any[]>([])

  useEffect(() => {
    request('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome', {});
  }, [request]);

  useEffect(() => {
    if (data && !loading) {
      setFilteredData(data.map((state) => ({ value: state.sigla })));
    }
  }, [data, loading]);

  return filteredData;
};

export const useGetCity = (state: string) => {
  const { request, data, loading } = useFetch();
  const [filteredData, setFilteredData] = useState<any[]>([])

  useEffect(() => {
    request(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`, {});
  }, [request, state]);

  useEffect(() => {
    if (data && !loading) {
      setFilteredData(data.map((city) => ({ value: city.nome })));
    }
  }, [data, loading]);

  return filteredData;
};
