import { TForm } from "./Utils/FormContext";

export const FORM_CREATE_USER_POST = (body: any) => {
  return {
    url: process.env.REACT_APP_API_TAQE_POST_ENDPOINT as string,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_API_TAQE_AUTHENTICATION_HEADER}`
      },
      body: JSON.stringify(body)
    }
  }
}
