import styles from './css/Support.module.css';

const Support: React.FC = () => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Dúvidas ou informações?</h4>
      <p className={styles.subtitle}>Fale com um de nossos consultores:</p>
      <a href="mailto:contato@taqe.com.br" className={styles.link}>Suporte TAQE</a>
    </div>
  )
}

export default Support;
